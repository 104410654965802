// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-complete-care-approach-js": () => import("./../../../src/pages/complete-care-approach.js" /* webpackChunkName: "component---src-pages-complete-care-approach-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-covid-19-policy-js": () => import("./../../../src/pages/covid-19-policy.js" /* webpackChunkName: "component---src-pages-covid-19-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-website-privacy-policy-js": () => import("./../../../src/pages/website-privacy-policy.js" /* webpackChunkName: "component---src-pages-website-privacy-policy-js" */),
  "component---src-templates-page-template-jsx": () => import("./../../../src/templates/page-template.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */),
  "component---src-templates-posts-page-template-jsx": () => import("./../../../src/templates/posts-page-template.jsx" /* webpackChunkName: "component---src-templates-posts-page-template-jsx" */)
}

